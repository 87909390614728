export const getMappedParagraph = data => {
  return data.allWpPage.edges[0].node.ACF_homepage.aboutUs.paragraph
}

export const getMappedCards = data => {
  return data.allWpPage.edges[0].node.ACF_homepage.aboutUs.cards.map(card => ({
    job: card.job,
    email: card.email,
    phone: card.phone,
    name: card.name,
    image: card.image.localFile,
  }))
}

export const getMappedMembers = members => {
  return members.map(member => ({
    imageSrc: member.image.localFile.publicURL,
    imageAlt: member.altText,
    link: member.link,
  }))
}

export const getMappedLinks = data => {
  return data.allWpPage.edges[0].node.ACF_homepage.usefulLinks.map(link => ({
    heading: link.heading,
    url: link.url,
  }))
}

export const getMappedNewsCards = data => {
  return data.allWpPost.nodes.map(post => ({
    title: post.title,
    date: post.date,
    content: post.content,
    image: post.featuredImage.node.localFile,
    slug: post.slug,
  }))
}

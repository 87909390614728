export const isObjectEmpty = obj =>
  obj && Object.keys(obj).length === 0 && obj.constructor === Object

export const isWindowObjectExist = () => typeof window !== "undefined"

export const getFirstSentence = string => {
  const regex = /.*?(\.)(?=\s[A-Z])/
  let m

  if ((m = regex.exec(string)) !== null) {
    return m[0]
  }
}

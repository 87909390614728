import React from "react"
import styled from "styled-components"
import { SingleLink } from "./single-link"
import { Container } from "../../theme/helpers"
import { Headline } from "../headline"
import { graphql, useStaticQuery } from "gatsby"
import { getMappedLinks } from "../../core/data.mappers"

const LinksContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 160px;
`

export const UsefulLinks = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpPage {
        edges {
          node {
            id
            ACF_homepage {
              usefulLinks {
                heading
                url
              }
            }
          }
        }
      }
    }
  `)

  const links = getMappedLinks(data)
  return (
    <Container id="links">
      <Headline>Naudingos nuorodos</Headline>
      <LinksContainer>
        {links.map((link, index) => (
          <SingleLink url={link.url} heading={link.heading} />
        ))}
      </LinksContainer>
    </Container>
  )
}

import React from "react"
import styled from "styled-components"
import { colors, mediaQuery } from "../../../theme"

const Text = styled.a`
  font-family: Manrope;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: ${colors.blue900};
  text-decoration-line: underline;
  position: relative;
  display: inline-block;
  margin: 20px;
  transition: transform 0.25s ease-in-out;

  &:hover {
    transform: scale(1.1, 1.1);
    cursor: pointer;
  }

  &:before {
    z-index: -1;
    content: "";
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: ${colors.blue100};
    position: absolute;
    display: inline-block;
    left: -12px;
    top: -10px;
  }

  ${mediaQuery("sm")} {
    margin: 20px 0;
  }
`
const Container = styled.div`
  width: 400px;

  ${mediaQuery("sm")} {
    width: 300px;
  }
`

export const SingleLink = ({ url, heading }) => {
  return (
    <Container>
      <Text href={url} target="_blank" rel="noopener noreferrer">
        {heading}
      </Text>
    </Container>
  )
}

import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Container } from "../../theme/helpers"
import { Card } from "./card"
import { Button } from "./button"
import { mediaQuery } from "../../theme"
import { Headline } from "../headline"
import { graphql, useStaticQuery } from "gatsby"
import { getMappedNewsCards } from "../../core/data.mappers"
import { getImage } from "gatsby-plugin-image"
import scrollTo from "gatsby-plugin-smoothscroll"

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${mediaQuery("md")} {
    justify-content: center;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const News = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpPost(sort: { fields: date }) {
        nodes {
          content
          slug
          title
          date(formatString: "YYYY MMMM DD", locale: "lt")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)

  const newsCards = getMappedNewsCards(data)
  const [count, setCount] = useState(3)

  useEffect(() => {
    if (count === 3) {
      return
    }

    count > 3 && scrollTo(`#news-card-${count - 2}`, "center")
  }, [count])

  return (
    <Container id="news">
      <Headline>Aktualijos</Headline>
      <CardsContainer>
        {newsCards.reverse().map((card, index) => (
          <Card
            heading={card.title}
            date={card.date}
            image={getImage(card.image)}
            slug={card.slug}
            id={`news-card-${index + 1}`}
            hidden={index + 1 > count}
          />
        ))}
      </CardsContainer>
      <ButtonContainer>
        <Button
          onClick={() => {
            setCount(count + 3)
          }}
          disabled={count >= newsCards.length}
        />
      </ButtonContainer>
    </Container>
  )
}

import { useCallback, useEffect, useState } from "react"
import { isWindowObjectExist } from "./helpers"

// TODO: refactor it to a cleaner version
export const useScroll = () => {
  const isBrowser = isWindowObjectExist()

  const [state, setState] = useState({
    lastScrollTop: 0,
    bodyOffset: isBrowser && document.body.getBoundingClientRect(),
    scrollY: isBrowser && document.body.getBoundingClientRect().top,
    scrollX: isBrowser && document.body.getBoundingClientRect().left,
    scrollDirection: "", // down, up
  })

  const handleScrollEvent = useCallback(() => {
    setState(prevState => {
      if (isBrowser) {
        const prevLastScrollTop = prevState.lastScrollTop
        const bodyOffset = document.body.getBoundingClientRect()

        return {
          setBodyOffset: bodyOffset,
          scrollY: -bodyOffset.top,
          scrollX: bodyOffset.left,
          scrollDirection: prevLastScrollTop > -bodyOffset.top ? "down" : "up",
          lastScrollTop: -bodyOffset.top,
        }
      }
    })
  }, [isBrowser])

  useEffect(() => {
    const scrollListener = e => {
      handleScrollEvent(e)
    }
    window.addEventListener("scroll", scrollListener)

    return () => {
      window.removeEventListener("scroll", scrollListener)
    }
  }, [handleScrollEvent])

  return {
    scrollY: state.scrollY,
    scrollX: state.scrollX,
    scrollDirection: state.scrollDirection,
  }
}

export const useEvent = (eventName, eventHandler, dependencies = []) => {
  useEffect(() => {
    if (eventName && eventHandler) {
      window.addEventListener(eventName, eventHandler)
      return () => window.removeEventListener(eventName, eventHandler)
    }
  }, [dependencies, eventHandler, eventName])
}

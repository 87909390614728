import React from "react"
import styled from "styled-components"
import { Container } from "../../theme/helpers"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { colors, mediaQuery } from "../../theme"
import { ReactComponent as Arrow } from "../../images/arrow.svg"
import { graphql, useStaticQuery } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"

const HeroContainer = styled.div`
  width: 100%;
  height: 80vh;
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
`

const HeroBackgroundImage = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.7;
      background: linear-gradient(71.69deg, #cee5f2 0.85%, #17170a 99.56%);
      transform: rotate(-180deg);
    }
  }
`
const Heading = styled.h1`
  font-family: Manrope;
  font-style: normal;
  font-weight: 800;
  font-size: 72px;
  line-height: 98px;
  color: ${colors.white};

  ${mediaQuery("sm")} {
    font-size: 60px;
  }
`

const Paragraph = styled.p`
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.black500};
  margin-top: 8px;
`
const Text = styled.div`
  z-index: 1;
`

const Button = styled.button`
  border: none;
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 32px;
  font-family: Manrope;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: ${colors.white};
  cursor: pointer;
`
const ButtonCircle = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid ${colors.black500};
  border-width: 2px;
  border-color: ${colors.white};
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ButtonLine = styled.div`
  width: 70px;
  height: 2px;
  background: ${colors.white};
  margin-right: 8px;
  transition: width 0.25s ease-in-out;

  ${Button}:hover & {
    width: 100px;
  }
`
export const Hero = () => {
  const {
    wpPage: { ACF_homepage },
  } = useStaticQuery(graphql`
    query {
      wpPage(slug: { eq: "home" }) {
        ACF_homepage {
          header {
            title
            text
            buttonText
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 1024)
                }
              }
            }
          }
        }
      }
    }
  `)
  const title = ACF_homepage.header.title
  const text = ACF_homepage.header.text
  const button_text = ACF_homepage.header.buttonText
  const image = ACF_homepage.header.image.localFile

  return (
    <>
      <HeroContainer id="page-top">
        <HeroBackgroundImage>
          <GatsbyImage image={getImage(image)} />
        </HeroBackgroundImage>

        <Container>
          <Text>
            <Heading>{title}</Heading>
            <Paragraph>{text}</Paragraph>

            <Button onClick={() => scrollTo("#about-us", "center")}>
              <ButtonCircle>
                <Arrow />
              </ButtonCircle>
              <ButtonLine />
              <span>{button_text}</span>
            </Button>
          </Text>
        </Container>
      </HeroContainer>
    </>
  )
}

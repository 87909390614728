import React from "react"
import styled from "styled-components"
import { ReactComponent as ArrowSvg } from "../../../images/button-arrow.svg"
import { colors } from "../../../theme"

const ButtonContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 62px;
  background: transparent;
  border: 1px solid #543be3;
  border-radius: 8px;
  width: 229px;
  cursor: pointer;
  margin-bottom: 120px;
  transition: transform 0.25s ease-in-out;

  &:disabled {
    display: none;
  }

  &:hover {
    transform: scale(1.1, 1.1);
  }

  small {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    font-feature-settings: "liga" off;
    color: ${colors.blue900};
  }
`

export const Button = ({ onClick, disabled }) => {
  return (
    <ButtonContainer onClick={onClick} disabled={disabled}>
      <small>Žiūrėti daugiau</small>
      <ArrowSvg />
    </ButtonContainer>
  )
}

import React from "react"
import styled from "styled-components"
import { colors } from "../../theme"
import scrollTo from "gatsby-plugin-smoothscroll"

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(71.69deg, #cee5f2 0.85%, #17170a 99.56%);
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open, isScrolled }) =>
    open && !isScrolled ? "translateX(0)" : "translateX(-100%)"};

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    letter-spacing: 0.5rem;
    color: ${colors.white};
    transition: color 0.3s linear;

    &:hover {
      cursor: pointer;
    }
  }
`

export const Menu = ({ open, setOpen, scrollY }) => {
  const menuItems = [
    { title: "Apie mus", id: "#about-us" },
    { title: "Tinklo nariai", id: "#network-members" },
    { title: "Naujienos", id: "#news" },
    { title: "Nuorodos", id: "#links" },
  ]

  return (
    <StyledMenu open={open} scrollY={scrollY}>
      {menuItems.map(item => (
        <a
          onClick={() => {
            setOpen(false)
            scrollTo(item.id, "center")
          }}
        >
          {item.title}
        </a>
      ))}
    </StyledMenu>
  )
}

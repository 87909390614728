import React from "react"
import { colors } from "../../../theme"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { ReactComponent as MobilePhoneSvg } from "../../../images/mobile-phone.svg"
import { ReactComponent as LetterSvg } from "../../../images/letter.svg"
import { ReactComponent as ArrowSvg } from "../../../images/small-arrow.svg"

const CardContainer = styled.div`
  max-width: 362px;
  min-height: 128px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 16px;
  box-sizing: border-box;
  background: ${colors.white};
  margin: 0 2.5px 24px;
`
const YellowElipse = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${colors.yellow900};
  border-radius: 50%;
  position: absolute;
  transform: translate(-6px, -3px);
`
const BlueElipse = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${colors.blue100};
  border-radius: 50%;
  position: absolute;
  transform: translate(6px, 3px);
`
const Image = styled.div`
  .person-image {
    border-radius: 50%;
    height: 100px;
    object-fit: cover;
    width: 100px;
  }
  .gatsby-image-wrapper {
    z-index: 0;
  }
`
const ImageContainer = styled.div`
  position: relative;
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  box-sizing: border-box;

  h3 {
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: capitalize;
    color: ${colors.black};
  }
  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: ${colors.black800};
  }
`

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
`
const Icon = styled.a`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${colors.blue100};
  position: relative;
  margin-right: 11px;
  transition: transform 0.25s ease-in-out;

  &:hover {
    cursor: pointer;
    transform: scale(1.5, 1.5);

    svg {
      color: ${colors.yellow900};
    }
  }

  .phone-icon {
    position: absolute;
    top: 8px;
    left: 10px;
  }

  .arrow-icon {
    position: absolute;
    top: 3px;
    left: 5px;
  }

  .letter-icon {
    position: absolute;
    top: 10px;
    left: 11px;
  }
`

export const Card = ({ email, job, name, image, phone }) => {
  return (
    <CardContainer>
      <ImageContainer>
        <BlueElipse />
        <YellowElipse />
        <Image>
          <GatsbyImage className="person-image" image={image} />
        </Image>
      </ImageContainer>
      <TextContainer>
        <h3>{name}</h3>
        <p>{job}</p>
        <Icons>
          <Icon href={`tel:${phone}`}>
            <MobilePhoneSvg className="phone-icon"></MobilePhoneSvg>
            <ArrowSvg className="arrow-icon"></ArrowSvg>
          </Icon>
          <Icon href={`mailto:${email}`}>
            <LetterSvg className="letter-icon"></LetterSvg>
            <ArrowSvg className="arrow-icon"></ArrowSvg>
          </Icon>
        </Icons>
      </TextContainer>
    </CardContainer>
  )
}

import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { Container } from "../../theme/helpers"
import { colors, mediaQuery } from "../../theme"
import { ReactComponent as LogoSvg } from "../../images/footer-logo.svg"
import scrollTo from "gatsby-plugin-smoothscroll"
import { useScroll, useEvent } from "../../core/hooks"
import { rem } from "polished"
import { Burger } from "../burger"
import { Menu } from "../menu"

const HeaderContainer = styled.nav`
  height: 72px;
  width: 100%;
  background: transparent;
  position: fixed;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  transition: padding 0.25s ease-in-out, background-color 0.25s ease-in-out,
    opacity 0.25s ease-in-out;

  svg {
    color: ${colors.white};
    transition: color 0.25s ease-in-out;
  }

  ${({ isScrolled }) =>
    isScrolled &&
    css`
      padding: ${rem(6)} 0;
      background-color: ${colors.white};
      box-shadow: 10px 10px 50px 3px rgba(39, 92, 141, 0.1);

      svg {
        color: #293241;
      }
    `}

  ul {
    list-style-type: none;

    li {
      display: inline;

      &:not(:last-child) {
        margin-right: 32px;
      }

      a {
        font-family: Manrope;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: ${colors.white};
        transition: color 0.25s ease-in-out;

        &:hover {
          cursor: pointer;
          color: ${colors.blue900};
        }

        ${({ isScrolled }) =>
          isScrolled &&
          css`
            color: ${colors.gray900};
          `}
      }
    }
  }
`
const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    &:hover {
      cursor: pointer;
    }

    &:first-child {
      height: 40px;
    }
  }
`

const Lists = styled.ul`
  ${mediaQuery("sm")} {
    display: none;
  }
`
const BurgerContainer = styled.div`
  z-index: 1;
  ${mediaQuery("sm", "min")} {
    display: ${({ open }) => (open ? "block" : "none")};
  }
`
export const Header = () => {
  const [open, setOpen] = useState(false)
  const { scrollY } = useScroll()
  const isScrolled = scrollY >= 12

  useEffect(() => open && setOpen(false), [scrollY])
  useEvent("resize", () => setOpen(false))

  return (
    <HeaderContainer isScrolled={isScrolled}>
      <Container>
        <Navigation>
          <a onClick={() => scrollTo("#page-top")}>
            <LogoSvg />
          </a>
          <BurgerContainer>
            <Burger open={open} setOpen={setOpen} isScrolled={isScrolled} />
          </BurgerContainer>

          <Menu open={open} setOpen={setOpen} scrollY={scrollY} />

          <Lists>
            <li>
              <a onClick={() => scrollTo("#about-us", "center")}>Apie mus</a>
            </li>
            <li>
              <a onClick={() => scrollTo("#network-members", "center")}>
                Tinklo nariai
              </a>
            </li>
            <li>
              <a onClick={() => scrollTo("#news", "center")}>Aktualijos</a>
            </li>
            <li>
              <a onClick={() => scrollTo("#links", "center")}>Nuorodos</a>
            </li>
          </Lists>
        </Navigation>
      </Container>
    </HeaderContainer>
  )
}

import React from "react"
import { colors, mediaQuery, Container } from "../../theme"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Card } from "./card"
import { graphql, useStaticQuery } from "gatsby"
import { getMappedParagraph, getMappedCards } from "../../core/data.mappers"

const Image = styled.div`
  width: 100%;
  margin: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  .gatsby-image-wrapper {
    height: 420px;
    border-radius: 8px;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(206, 229, 242, 0.3) 0%,
        rgba(84, 59, 227, 0.3) 100%
      );
      border-radius: 8px;
    }
  }
`

const TextContainer = styled.div`
  display: flex;
  margin: 56px 0 96px;

  h2 {
    font-family: Manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 48px;
    color: ${colors.black900};
    min-width: 300px;
  }

  ${mediaQuery("md")} {
    flex-direction: column;

    h2 {
      margin-bottom: 16px;
    }
  }
`

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 80px;

  ${mediaQuery("xl")} {
    justify-content: space-around;
  }
`
const Content = styled.div`
  font-family: Manrope;
  font-size: 18px;
  line-height: 32px;
  color: ${colors.black800};
  margin: 0 0 64px;
  text-align: justify;

  ul {
    list-style-type: disc;
    margin: 16px 0 16px 32px;
  }
  p {
    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }
`

export const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpPage {
        edges {
          node {
            id
            ACF_homepage {
              aboutUs {
                paragraph
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                cards {
                  email
                  job
                  name
                  phone
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(height: 1024)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const paragraph = getMappedParagraph(data)
  const aboutCards = getMappedCards(data)
  return (
    <Container id="about-us">
      <TextContainer>
        <h2>Apie mus</h2>
        <Content dangerouslySetInnerHTML={{ __html: paragraph }} />
      </TextContainer>
      <CardsContainer>
        {aboutCards.map((card, index) => (
          <Card
            name={card.name}
            email={card.email}
            job={card.job}
            phone={card.phone}
            image={getImage(card.image)}
          />
        ))}
      </CardsContainer>
    </Container>
  )
}

import * as React from "react"
import "reset-css"
import { Header } from "../components/header"
import { Footer } from "../components/footer"
import { Hero } from "../components/hero"
import { AboutUs } from "../components/about-us"
import { GlobalCss } from "../theme"
import { News } from "../components/news"
import { UsefulLinks } from "../components/useful-links"
import { NetworkMembers } from "../components/network-members"
import { SEOContext } from "gatsby-plugin-wpgraphql-seo"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { graphql } from "gatsby"

const IndexPage = ({
  data: {
    wpPage,
    wp: { seo },
  },
}) => {
  return (
    <SEOContext.Provider value={{ global: seo }}>
      <Seo post={wpPage} />
      <GlobalCss />
      <Header />
      <Hero />
      <AboutUs />
      <NetworkMembers />
      <News />
      <UsefulLinks />
      <Footer />
    </SEOContext.Provider>
  )
}
export const query = graphql`
  query SiteInfoQuery {
    wp {
      seo {
        contentTypes {
          post {
            title
            schemaType
            metaRobotsNoindex
            metaDesc
          }
          page {
            metaDesc
            metaRobotsNoindex
            schemaType
            title
          }
        }
        webmaster {
          googleVerify
          yandexVerify
          msVerify
          baiduVerify
        }
        schema {
          companyName
          personName
          companyOrPerson
          wordpressSiteName
          siteUrl
          siteName
          inLanguage
          logo {
            sourceUrl
            mediaItemUrl
            altText
          }
        }
        social {
          facebook {
            url
            defaultImage {
              sourceUrl
              mediaItemUrl
            }
          }
          instagram {
            url
          }
          linkedIn {
            url
          }
          mySpace {
            url
          }
          pinterest {
            url
            metaTag
          }
          twitter {
            username
          }
          wikipedia {
            url
          }
          youTube {
            url
          }
        }
      }
    }
    wpPage(slug: { eq: "home" }) {
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`
export default IndexPage

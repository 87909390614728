import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { graphql, Link, useStaticQuery } from "gatsby"
import { colors, Container } from "../../theme"
import { ReactComponent as PurpleBlobSvg } from "../../images/purple-blob.svg"
import { ReactComponent as YellowBlobSvg } from "../../images/yellow-blob.svg"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from "swiper"
import { getMappedMembers } from "../../core/data.mappers"
import "swiper/swiper.scss"

SwiperCore.use([Autoplay])

const MembersContainer = styled.div`
  width: 100%;
  height: 226px;
  position: relative;
  background: ${colors.white};
  margin-bottom: 96px;
  overflow: hidden;

  h3 {
    text-align: center;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 48px 0 40px 0;
    z-index: 5;
    position: relative;
  }

  .yellow-blob {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .purple-blob {
    position: absolute;
    left: 0;
    top: 0;
  }
`

const SvgContainer = styled.div`
  width: 100%;
`

const ImageContainer = styled.div`
  width: 100%;
  height: 96px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`

export const NetworkMembers = () => {
  const [slidesInView, setSlidesInView] = useState(5)

  useEffect(() => {
    window && window.dispatchEvent(new Event("resize"))
  }, [])

  const {
    wpPage: { ACF_homepage },
  } = useStaticQuery(graphql`
    query {
      wpPage(slug: { eq: "home" }) {
        ACF_homepage {
          members {
            image {
              altText
              localFile {
                publicURL
              }
            }
            link
          }
        }
      }
    }
  `)

  const members = getMappedMembers(ACF_homepage.members)

  return (
    <MembersContainer id="network-members">
      <h3>Tinklo nariai</h3>
      <SvgContainer>
        <PurpleBlobSvg className="purple-blob" />
        <YellowBlobSvg className="yellow-blob" />
      </SvgContainer>

      <Container>
        <Swiper
          autoplay={{ delay: 2000 }}
          spaceBetween={100}
          slidesPerView={slidesInView}
          breakpoints={{
            320: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 4,
            },
            1024: {
              slidesPerView: 5,
            },
          }}
        >
          {members.map(member => (
            <SwiperSlide>
              <ImageContainer>
                <a href={member.link} target="_blank" rel="noreferrer">
                  <img src={member.imageSrc} alt={member.imageAlt}></img>
                </a>
              </ImageContainer>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </MembersContainer>
  )
}

import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { colors, mediaQuery } from "../../../theme"
import { ReactComponent as CalendarSvg } from "../../../images/callendar.svg"
import { Link } from "gatsby"

const CardContainer = styled.div`
  max-width: 374px;
  min-height: 374px;
  background: ${colors.white};
  box-shadow: 0px 6px 42px rgba(0, 0, 0, 0.103884);
  border-radius: 10px;
  margin: 0 40px 48px 0;
  transition: transform 0.25s ease-in-out;
  padding-bottom: 30px;
  text-decoration: "none";

  p {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: ${colors.black900};
    margin: 0 28px;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.05, 1.05);
  }

  ${mediaQuery("md")} {
    margin: 0 0 48px 0;
  }
`

const Image = styled.div`
  .gatsby-image-wrapper {
    width: 100%;
    height: 224px;
    border-radius: 10px 10px 0px 0px;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.7;
      background: linear-gradient(
        180deg,
        rgba(233, 255, 112, 0.3) 0%,
        rgba(84, 59, 227, 0.3) 100%
      );
      transform: rotate(-180deg);
    }
  }
`

const Event = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0 14px 28px;

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: ${colors.blue900};
    margin-left: 16px;
  }
`

export const Card = ({ date, heading, image, slug, id, hidden }) => {
  return (
    <Link
      to={`aktualijos/${slug}`}
      style={{
        textDecoration: "none",
        display: hidden ? "none" : "flex",
      }}
      id={id}
    >
      <CardContainer>
        <Image>
          <GatsbyImage image={image} />
        </Image>
        <Event>
          <CalendarSvg />
          <p>{date}</p>
        </Event>
        <p>{heading}</p>
      </CardContainer>
    </Link>
  )
}

import React from "react"
import styled from "styled-components"
import { colors } from "../../theme"

const Text = styled.p`
  font-weight: 800;
  font-size: 48px;
  line-height: 48px;
  position: relative;
  display: inline-block;
  margin-bottom: 64px;

  &:after {
    z-index: -1;
    content: "";
    width: 104%;
    height: 20px;
    background: ${colors.blue100};
    position: absolute;
    bottom: 0;
    left: -2%;
  }
`

export const Headline = ({ children }) => {
  return <Text>{children}</Text>
}
